@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: 'Poppins', sans-serif;
}
:root{
    --primary: #fff;
    --secondary: #83C5C1;
    --details: #DDFAF9;
    --text:#1B5F60;
}
html, body, #root{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}
h1,h2,h3,h4,h5{
    margin: 0;
}

h1 {
  font-size: 3.052rem;
}

h2 {font-size: 2.441rem;}

h3 {font-size: 1.953rem;}

h4 {font-size: 1.563rem;}

h5 {font-size: 1.25rem;}

p{
    font-size: 1rem;
}
@media (max-width:1350px) {
  p{
    font-size: 1rem;
  }
  h1 {
    font-size:  2.441rem;
  }
  
  h2 {font-size: 2rem;}
  
  h3 {font-size: 1.453rem;}
  
  h4 {font-size: 1.263rem;}
  
  h5 {font-size: 1.25rem;}
}
table{
    border: 1px solid var(--primary);
    border-collapse: collapse;
    text-align: center;
    color: var(--primary);
}
th,td{
    background-color: var(--secondary);
    border: 1px solid var(--primary);
}
th.white{
    background-color: var(--primary);
    color: #000;
}
.carousel li{
    display: none;
}
.carousel li:first-child{
    display: block;
}
#tsparticles{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
}
#tsparticles2{
    position: absolute;
    z-index: 0;
    top:0;
    width: 100%;
    height: 100%;
}
@keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    80% {
      -webkit-transform: translateY(6.4px);
              transform: translateY(6.4px);
    }
    90% {
      -webkit-transform: translateY(-6.4px);
              transform: translateY(-6.4px);
    }
  }
@-webkit-keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 682.2797241210938px;
      stroke-dasharray: 682.2797241210938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 682.2797241210938px;
    }
  }
  
  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 682.2797241210938px;
      stroke-dasharray: 682.2797241210938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 682.2797241210938px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(173, 224, 221);
    }
  }
  
  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(173, 224, 221);
    }
  }
  
  .logo_element1{
    -webkit-animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
                         animate-svg-fill-1 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
            animation: animate-svg-stroke-1 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
                 animate-svg-fill-1 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }
  
  @-webkit-keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 887.8119506835938px;
      stroke-dasharray: 887.8119506835938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 887.8119506835938px;
    }
  }
  
  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 887.8119506835938px;
      stroke-dasharray: 887.8119506835938px;
    }
  
    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 887.8119506835938px;
    }
  }
  
  @-webkit-keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(173, 224, 221);
    }
  }
  
  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(173, 224, 221);
    }
  }
  
  .logo_element2 {
    -webkit-animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.02s both,
                         animate-svg-fill-2 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
            animation: animate-svg-stroke-2 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.02s both,
                 animate-svg-fill-2 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }
  

  @keyframes heartIntro {
    0%{
      transform: translate(50%,-50%);
      right: 50%;
    }
    50%{
      transform: translate(50%,-50%);
      right: 50%;
    }
    100%{
      transform: translate(0,-50%);
      right: 0;
    }
  }
  @keyframes HeroTextIntro {
    0%{
      opacity: 0;
      transform: translate(-50%,-50%);
    }
    50%{
      opacity: 0;
      transform: translate(-50%,-50%);
    }
    100%{
      opacity: 1;      
      transform: translate(0,-50%);
    }
  }
  @keyframes NavBarIntro {
    0%{
      opacity: 0;
      transform: translate(0,-50%);
    }
    50%{
      opacity: 0;
      transform: translate(0,-50%);
    }
    100%{
      opacity: 1;      
      transform: translate(0,0);
    }
  }
  @keyframes MobileRotation {
    0%{
      transform: rotate(0deg)
    }
    25%{
      transform: rotate(25deg)
    }
    50%{
      transform: rotate(0deg)
    }
    75%{
      transform: rotate(-25deg)
    }
    100%{
      transform: rotate(0deg)
    }
    
  }
  @keyframes ComputerRotation {
    0%{
      transform: rotate(0deg)
    }
    25%{
      transform: rotate(-25deg)
    }
    50%{
      transform: rotate(0deg)
    }
    75%{
      transform: rotate(25deg)
    }
    100%{
      transform: rotate(0deg)
    }
    
  }
.particles{
  width: 40px;
  height: 40px;
  position: absolute;
}
.particles:nth-child(1){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-right 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0s infinite normal none;
}
.particles:nth-child(2){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-top 5s cubic-bezier(0.1, 0.3, 1, 1) 0s infinite normal none;
}
.particles:nth-child(3){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-right 5s cubic-bezier(0.1, 0.3, 1, 1) 0.1s infinite normal none;
}
.particles:nth-child(4){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-top2 5s cubic-bezier(0.1, 0.3, 1, 1) 0.1s infinite normal none;
}
.particles:nth-child(5){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-top3 5s cubic-bezier(0.1, 0.3, 1, 1) 0.1s infinite normal none;
}
.particles:nth-child(6){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-right2 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0.1s infinite normal none;
}
.particles:nth-child(7){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-right2 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0.2s infinite normal none;
}
.particles:nth-child(8){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-right4  5s cubic-bezier(.09,.12,.81,.42) 0.5s infinite normal none;
}
.particles:nth-child(9){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-right4 5s cubic-bezier(.09,.12,.81,.42) 0.1s infinite normal none;
}
.particles:nth-child(10){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-right3 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0.4s infinite normal none;
}
.particles:nth-child(11){
  background-image: url("./assets/img/general/hero/particle.png");
  background-size: cover;
  animation: slide-bl-right3 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0.1s infinite normal none;
}
.particles:nth-child(12){
  background-image: url("./assets/img/general/hero/globule.png");
  background-size: cover;
  animation: slide-bl-top4 5s cubic-bezier(0.1, 0.3, 1, 0.9) 0.25s infinite normal none;
}
  @keyframes slide-bl-right {
    0%{
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-200px) translateX(500px);
      opacity: 0;
    }
  }
  @keyframes slide-bl-right2 {
    0%{
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-700px) translateX(200px);
      opacity: 0;
    }
  }
  @keyframes slide-bl-right3 {
    0%{
      transform: translateY(0) translateX(0);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-100px) translateX(500px);
      opacity: 0;
    }
  }
  @keyframes slide-bl-right4 {
    0%{
      transform: translateY(0) translateX(0) scale(1);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
      transform: scale(2);
    }
    100%{
      transform: translateY(-300px) translateX(500px) scale(0.5);
      opacity: 0;
    }
  }
  @keyframes slide-bl-top {
    0%{
      transform: translateY(0) translateX(0) scale(1);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-500px) translateX(-200px) scale(1.1);
      opacity: 0;
    }
  }
  @keyframes slide-bl-top2 {
    0%{
      transform: translateY(0) translateX(0) scale(0.7);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-500px) translateX(-50px) scale(1);
      opacity: 0;
    }
  }
  @keyframes slide-bl-top3 {
    0%{
      transform: translateY(0) translateX(0) scale(0.6);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-500px) translateX(50px) scale(1);
      opacity: 0;
    }
  }
  @keyframes slide-bl-top4 {
    0%{
      transform: translateY(0) translateX(0) scale(1);
      opacity: 1;
    }
    50%{
      opacity: 0.7;
    }
    100%{
      transform: translateY(-500px) translateX(-190px) scale(1.1);
      opacity: 0;
    }
  }